<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>Формы документов, связанных с противодействием коррупции, для заполнения</h5>
            <tricolor-line class="tricolor_margin"/>
            <div v-for="(item, i) of content" :key="i+'info'" class="partner-info">
                <div class="img_block"></div>
                <div class="info_block">
                    <p>{{item.title}}</p>
                    <download-file :dataFile="{url: item.url, size: item.size, type: item.type}" class="download-file"/>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import DownloadFile from '@/components/DownloadFile'
export default {
  name: 'DocumentForms',
  components: {
    'tricolor-line': TricolorLine,
    'bread-crumb': BreadCrumb,
    'download-file': DownloadFile
  },
  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Противодействие коррупции',
          path: '/'
        },
        {
          title: 'Формы документов, связанных с противодействием коррупции, для заполнения'
        }
      ],
      content: [
        {
          title: 'Справка о доходах, расходах, об имуществе и обязательствах имущественного характера',
          url: '/',
          size: 217,
          type: 'PDF'
        },
        {
          title: 'Уведомление о получении подарка',
          url: '/',
          size: 217,
          type: 'PDF'
        },
        {
          title: 'Уведомление о факте обращения в целях склонения работника к совершению коррупционных правонарушений',
          url: '/',
          size: 217,
          type: 'PDF'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
.tricolor_margin{
    margin: 1.8rem 0 2rem 0;
}
.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.875rem;
}
.partner-info:last-child{
    margin-bottom: 0;
}
.img_block{
    position: relative;
    width: 2.82rem;
    height: 2.82rem;
    border-radius: 50%;
    background: url('../../assets/icons/document_icon.svg') center center no-repeat;
    background-size: 1rem;
    background-color: #eaf1f8;
}
.block_partner:last-child :last-child{
    margin-bottom: 0;
}
.info_block{
    margin-left: 1rem;
    width: calc(100% - 1rem - 2.82rem);
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
}
.download-file{
    margin-top: .97rem;
}
@media screen and (max-width: 768px){
    .white-container{
        width: auto;
        margin: 0 .75rem;
        padding: 1.86rem 1.25rem;
    }
    .download-file{
        margin-top: .75rem;
    }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
}
</style>
